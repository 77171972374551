.icon {
  width: 100px;
  height: 100px;

  path {
    fill: #e8eaef;

    &:last-child,
    &:nth-child(2) {
      fill: #3498db;
    }
  }
}

.wrapperLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.button_loader {
  width: 35px;
  height: 35px;
}
